<template>
	<div class="project-container">
		<div class="project-image-container"></div>
		<img class="project-image" :src="topImage" />
		<div class="project-text">
			<h2>Totalrenovering</h2>
			<p>
                Når man skal totalrenovere hjemmet sitt er det mye å tenke på. Vi i Ellingsen Byggservice forstår dette og har derfor partnere innen kraning, elektro, maling, hvitevarer, i tillegg til vår egen spesialkompetanse innenfor bygg!
                Velger du totalrenovering gjennom oss kan du derfor slappe helt av også ordner vi resten av ditt nye drømmehjem! 
			</p>
			<p>
				Ta kontakt for en uforpliktende og hyggelig konsultasjon. Kontaktdetaljer finner du <a href="/kontakt">her</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['topImage'],
}
</script>

<style scoped>
.project-image {
	width: 1000px;
	max-width: 70%;
	padding-top: 2%;
}

</style>
